'use client';

import { ISubServiceComponent } from '@sapientpro/sapientpro-data-models';
import { DeliveredValues } from '@sapientpro/sapientpro-data-models/types/case/contentoo-case';
import classnames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect } from 'react';
import { ContactFormExpert } from '@sapientpro/sapientpro-data-models/types/components/shared/contact-form-expert';
import Button, { ButtonVariants } from '../../components/Button';
import MicroserviceCard from './MicroserviceCard';
import contactModalTriggerButton, { contactExpert } from '../../store/contactForm';
import { theme, Theme } from '../../store/theme';
import styles from './Subservice.module.scss';

const Microservices = ({ data, expert }: { data: ISubServiceComponent | DeliveredValues, expert?: ContactFormExpert }) => {
  const appTheme = useAtomValue<Theme>(theme);
  const setContactExpert = useSetAtom(contactExpert);
  const setContactsModalTrigger = useSetAtom(contactModalTriggerButton);

  useEffect(() => {
    setContactExpert(expert || null);
  }, []);

  return (
    <section className={classnames(styles.services, 'container')}>
      {data.title && <h4 className={classnames('title4', styles.services__title)}>{data.title}</h4>}
      <div className={styles.services__content}>
        {(data.__component === 'contentoo-case.delievered-values' ? data.content : data.subServices).map((microserviceCard, index) => (
          <MicroserviceCard
            key={microserviceCard.id}
            title={microserviceCard.title}
            text={microserviceCard.description}
            visualsType={(index + 1) % 4 || 4}
            visuals={{
              big: `/media/service_visuals/${(index + 1) % 4 || 4}/big_${appTheme}.webp`,
              medium: `/media/service_visuals/${(index + 1) % 4 || 4}/medium_${appTheme}.webp`,
              small: `/media/service_visuals/${(index + 1) % 4 || 4}/small_${appTheme}.webp`,
            }}
          />
        ))}
      </div>
      <Button
        variant={ButtonVariants.OUTLINED}
        className={styles.services__button}
        icon={(
          <svg>
            <use
              xlinkHref='/media/icon-nest.svg#iconNest'
              href='/media/icon-nest.svg#iconNest'
            />
          </svg>
        )}
        iconPosition='right'
        iconSize={{ width: 24, height: 24 }}
        onClick={(e) => setContactsModalTrigger(`${(e.target as HTMLElement).innerText} | Subservices section`)}
      >
        Start now
      </Button>
    </section>
  );
};

export default Microservices;
